import ProductImage from './ProductImage';
import ProductVariant from './ProductVariant';

class Product {
  constructor(product, siteUID, site) {
    this.badge = product.ProductBadge?.value;
    this.bait = product.ProductSegmentationBait?.value;
    this.brand = product.ProductBrand?.value;
    this.category = product.ProductCategory?.value;
    this.cancerReprTox = product.ProductCancerReprTox?.value;
    this.chemicals = product.ProductChemicals?.value;
    this.hookBarbType = product.ProductHookBarbType?.value;
    this.hookEye = product.ProductHookEye?.value;
    this.hookEyeAngle = product.ProductHookEyeAngle?.value;
    this.hookEyeTurn = product.ProductHookEyeTurn?.value;
    this.hookFinish = product.ProductHookFinish?.value;
    this.hookLength = product.ProductHookLength?.value;
    this.hookPoint = product.ProductHookPoint?.value;
    this.hookShank = product.ProductHookShank?.value;
    this.hookShape = product.ProductHookShape?.value;
    this.hookSubPoint = product.ProductHookSubPoint?.value;
    this.hookSubShank = product.ProductHookSubShank?.value;
    this.hookTrademark = product.ProductHookTrademark?.value;
    this.hookWeighted = product.ProductHookWeighted?.value;
    this.hookWideGap = product.ProductHookWideGap?.value;
    this.hookWire = product.ProductHookWire?.value;
    this.hookWireStrength = product.ProductHookWireStrength?.value;
    this.id = product.id;
    this.images = Product.mapImages(product.resources);
    this.inventory = {
      value:
        product.inventory && product.inventory[site?.inventoryField] > 0
          ? true
          : false,
    };

    const launchWindows = Array.isArray(product.launchWindows)
      ? product.launchWindows.map(
          (launchWindow) => parseInt(launchWindow.slice(0, 4)) || null
        )
      : [];
    this.launchWindow = Math.max(...launchWindows);
    this.location = product.ProductLocation?.value;
    this.longDescription = product.ProductLongDescription?.value;
    this.name = product.ProductName?.value;
    this.path = product.path;
    this.prices = product.prices || {};
    this.prop65Comment = product.ProductProp65Comment?.value;
    this.quantitySold = {
      value:
        product.quantitySolds && product.quantitySolds[site?.quantitySoldField],
    };
    this.score = product.ProductYotpoReviewScore?.value || { value: 0 };
    this.searchKeys = product.searchKeys;
    this.season = product.ProductSeason?.value;
    this.series = product.ProductSeries?.value;
    this.shopId = product.shopId;
    this.sizeChartFields = Product.mapSizeChartFields(product.ProductSizeChart);
    this.sizeStyle = product.ProductSizeStyle?.value;
    this.sourceId = product.entityId;
    this.species = product.ProductSegmentationSpecies?.value;
    this.stiffness = product.ProductStiffness?.value;
    this.subCategory = product.ProductSubCategory?.value;
    this.shortDescription = product.ProductShortDescription?.value;
    this.technique = product.ProductSegmentationTechnique?.value;
    this.title = product.ProductName?.value;
    this.trademark = product.ProductTrademark?.value;
    this.totalReviews = product.ProductYotpoReviewCount?.value || { value: 0 };
    this.variants = Product.mapVariants(
      product.childrenInRiverProductVariant,
      site
    );
    this.variableFields = Product.mapVariableFields(
      product.ProductVariantSelector
    );
    this.videos = Product.mapVideos(product);
    this.warningColor = product.ProductWarningColor?.value;
    this.warningType = product.ProductWarningType?.value;
    this.waterType = product.ProductSegmentationWaterType?.value;
    this.weedless = product.ProductWeedless?.value;

    this.constructorName = 'Product';
    this.siteUID = siteUID;
  }

  static mapImages(resources) {
    return (
      resources
        ?.map((resource) => new ProductImage(resource))
        .sort((a, b) => (a.sortKey < b.sortKey ? -1 : 1)) || []
    );
  }

  static mapVariants(variants, site) {
    return (
      variants
        ?.filter(
          (variant) => variant[site?.productItemFilterField]?.value?.value
        )
        ?.map((variant) => new ProductVariant(variant, site))
        .sort((a, b) => (a.sizeSort?.value < b.sizeSort?.value ? -1 : 1)) || []
    );
  }

  static mapVariableFields(variableFields) {
    const mappings = {
      ItemColorName: 'colorName',
      ItemDiameter: 'diameterSize',
      ItemDiameterSize: 'diameterSize',
      ItemHookSize: 'hookSize',
      ItemLength: 'lengthSize',
      ItemLengthSize: 'lengthSize',
      ItemPackCode: 'packCode',
      ItemPackQuantity: 'packQuantity',
      ItemRodAction: 'rodAction',
      ItemRodPower: 'rodPower',
      ItemRodSections: 'rodSections',
      ItemRodStyle: 'rodStyle',
      ItemSize: 'size',
      ItemSKUID: 'sku',
      ItemTestRating: 'testSize',
      ItemTestRatingSize: 'testSize',
      ItemWeight: 'weightSize',
      ItemWeightSize: 'weightSize',
      RodStyle: 'rodStyle',
    };

    return (
      variableFields?.value?.map((field) => ({
        value: mappings[field.key],
        title: field.value,
      })) || []
    );
  }

  static mapSizeChartFields(sizeChartFields) {
    const mappings = {
      ItemDiameter: 'diameterSize',
      ItemDiameterSize: 'diameterSize',
      ItemGap: 'gapMetric',
      ItemHookSize: 'hookSize',
      ItemLength: 'lengthSize',
      ItemLengthSize: 'lengthSize',
      ItemLureDepth: 'lureDepth',
      ItemPackCode: 'packCode',
      ItemRodAction: 'rodAction',
      ItemRodLineClass: 'rodLineClass',
      ItemRodLineClassSize: 'rodLineClass',
      ItemRodLureWeight: 'rodLureWeight',
      ItemRodLureWeightSize: 'rodLureWeight',
      ItemRodPower: 'rodPower',
      ItemRodSections: 'rodSections',
      ItemRodStyle: 'rodStyle',
      ItemRodTransportLengthSize: 'rodTransportLength',
      ItemSize: 'size',
      ItemSKUID: 'sku',
      ItemTestRating: 'testSize',
      ItemTestRatingSize: 'testSize',
      ItemWeight: 'weightSize',
      ItemWeightSize: 'weightSize',
    };

    return (
      sizeChartFields?.value?.map((field) => ({
        value: mappings[field.key],
        title: field.value,
      })) || []
    );
  }

  static mapVideos(product) {
    const videos = [];

    if (product.ProductVideoUrl1?.value.value)
      videos.push(product.ProductVideoUrl1.value);
    if (product.ProductVideoUrl2?.value.value)
      videos.push(product.ProductVideoUrl2.value);
    if (product.ProductVideoUrl3?.value.value)
      videos.push(product.ProductVideoUrl3.value);
    if (product.ProductVideoUrl4?.value.value)
      videos.push(product.ProductVideoUrl4.value);

    return videos;
  }
}

export default Product;
