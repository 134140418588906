import React from 'react';

import { getPrice } from '../../../utils/utils';

const Totals = ({ cart, site }) => {
  const subTotal = cart?.cost.subtotalAmount.amount || 0;
  const total = cart?.cost.totalAmount.amount || 0;
  const isDiscounted = subTotal - total;

  const duty = cart?.cost.totalDutyAmount?.amount || 0;
  const tax = cart?.cost.totalTaxAmount?.amount || 0;

  return (
    <div className="cart__totals">
      <div className="totals__total">
        <span>Subtotal</span>
        <span>{getPrice(site, subTotal)}</span>
      </div>

      <div className="totals__total">
        <span>Discounts</span>
        <span>
          {isDiscounted ? '-' : ''}
          {getPrice(site, subTotal - total)}
        </span>
      </div>

      <div className="totals__total">
        <span>
          <strong>Estimated Total</strong>
        </span>
        {isDiscounted ? (
          <span className="price">
            <span className="price price--old">{getPrice(site, subTotal)}</span>
            <span className="price price--new">{getPrice(site, total)}</span>
          </span>
        ) : (
          <span className="price">{getPrice(site, total)}</span>
        )}
      </div>
    </div>
  );
};

export default Totals;
