import Link from '../Link';
import React, { Component } from 'react';

import DesktopMenuPaneItem from './DesktopMenuPaneItem';
import { getCmsValue } from '../../utils/utils';

class DesktopMenuPane extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, linkTarget, site, title } = this.props;

    return (
      <div className="desktop-menu__pane">
        <h4 className="desktop-menu__pane-header">
          <Link
            className="desktop-menu__pane-header-link"
            site={site}
            to={linkTarget}
          >
            {title}
          </Link>
        </h4>

        <ul className="desktop-menu__pane-list">
          {children.length > 0 &&
            children.map((child) => (
              <DesktopMenuPaneItem
                key={child.id}
                children={child.children}
                linkTarget={child.linkTarget}
                site={site}
                title={getCmsValue(child.title, site)}
              />
            ))}
        </ul>
      </div>
    );
  }
}

export default DesktopMenuPane;
